import React from 'react'

import Link from 'next/link'
import { classNames } from 'app/utils/classes'
import { useTranslation } from 'next-i18next'

type SignUpButtonProps = {
  darkText?: boolean
}

export const SignUpButton = ({ darkText = false }: SignUpButtonProps) => {
  const { t } = useTranslation('common')
  return (
    <Link
      href="/#booking"
      className={classNames(
        'focus-visible:outline-brand hidden px-5 py-2 text-base leading-7 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:inline-block',
        darkText ? 'text-gray-900' : 'text-white'
      )}
    >
      {t('book-demo')}
    </Link>
  )
}
