import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import images from 'app/types/images'
import Image from 'next/image'
import { SignUpButton } from './SignUpButton'
import { SignInButton } from './SignInButton'
import { useRouter } from 'next/router'
import { classNames } from 'app/utils/classes'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

interface LandingNavigationProps {
  activePage?:
    | 'ticket-solution'
    | 'crm'
    | 'career'
    | 'about-liveday'
    | 'contact'
  dropdownActivePage?: string
  darkText?: boolean
}

export const LandingNavigation = ({
  activePage,
  dropdownActivePage,
  darkText,
}: LandingNavigationProps) => {
  const { t } = useTranslation('common')
  const navigation = [
    {
      name: t('one-platform'),
      current: false,
      dropdown: [
        { name: t('platform'), href: '/venue/platform', iconUrl: 'platform' },
        {
          name: t('ticket-solution'),
          href: '/venue/solution',
          iconUrl: 'ticket_dropdown',
        },
        { name: t('crm'), href: '/venue/crm', iconUrl: 'crm' },
        {
          name: t('marketing'),
          href: '/venue/marketing',
          iconUrl: 'marketing',
        },
      ],
    },
    { current: false, name: t('about-liveday'), href: '/about' },
    { current: false, name: t('career'), href: '/venue/career' },
    { current: false, name: t('press'), href: '/press' },
    { current: false, name: t('contact'), href: '/venue/contact_me' },
  ]
  const router = useRouter()

  const [height, setHeight] = useState(0)

  const [sticky, setSticky] = useState(false)
  const listenScrollEvent = () => {
    const element = document.getElementById('navbar')
    if (element) {
      setHeight(element.getBoundingClientRect().height)
    }
    window.scrollY > 10 ? setSticky(true) : setSticky(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
    return () => {
      window.removeEventListener('scroll', listenScrollEvent)
    }
  }, [])

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const secondDropdownRef = useRef<HTMLDivElement>(null)

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      secondDropdownRef.current &&
      !secondDropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const shouldBeDark = (darkText ?? false) || sticky

  return (
    <Disclosure as="header">
      {({ open }) => (
        <>
          <div
            style={{
              height: sticky ? height + 5 : 'auto',
            }}
          >
            <nav
              id="navbar"
              className={
                sticky
                  ? 'fixed left-0 top-0 z-20 w-screen bg-white px-6 sm:px-0'
                  : ''
              }
              aria-label={t('global') ?? ''}
              style={{
                transition: 'all 1s',
              }}
            >
              <div
                className={
                  'container mx-auto flex max-w-7xl items-center justify-between py-4'
                }
              >
                <div className="lg:flex lg:gap-x-12">
                  <Link href="/">
                    <Image
                      src={images.logo}
                      quality={100}
                      alt=""
                      className="h-8 w-32"
                      width={128}
                      height={128}
                    />
                  </Link>
                </div>
                <div className="hidden sm:flex sm:gap-x-12">
                  {navigation?.map((item, idx) => (
                    <div key={`${item.name}-${idx}`} className="relative">
                      {item.dropdown ? (
                        <>
                          <a
                            href={item.href}
                            className={`cursor-pointer text-base leading-6 ${
                              router.pathname === item.href &&
                              activePage !== undefined
                                ? 'text-brand'
                                : shouldBeDark
                                ? 'text-gray-900'
                                : 'text-white'
                            }`}
                            onClick={handleDropdownClick}
                          >
                            {item.name}
                            {isDropdownOpen ? (
                              <ChevronUpIcon
                                className={`-mt-1 ml-1 inline-block h-4 w-4 ${
                                  shouldBeDark ? 'text-gray-900' : 'text-white'
                                }`}
                              />
                            ) : (
                              <ChevronDownIcon
                                className={`-mt-1 ml-1 inline-block h-4 w-4 ${
                                  shouldBeDark ? 'text-gray-900' : 'text-white'
                                }`}
                              />
                            )}
                          </a>
                          <div
                            ref={dropdownRef}
                            className={`absolute mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${
                              isDropdownOpen ? 'block' : 'hidden'
                            }`}
                          >
                            {item.dropdown?.map((option, odx) => (
                              <a
                                key={`${option.name}-${odx}`}
                                href={option.href}
                                className={`hover:text-brand flex items-center rounded-md px-4 py-2 text-sm ${
                                  dropdownActivePage === option.href
                                    ? 'text-brand'
                                    : 'text-gray-700'
                                }`}
                              >
                                <div className="relative mr-2 h-5 w-5">
                                  <Image
                                    src={
                                      images[
                                        option.iconUrl as keyof typeof images
                                      ]
                                    }
                                    alt={option.name}
                                    className="text-gray-900"
                                    fill
                                  />
                                </div>
                                {option.name}
                              </a>
                            ))}
                          </div>
                        </>
                      ) : (
                        <a
                          href={item.href}
                          className={`cursor-pointer text-base leading-6 ${
                            router.pathname === item.href &&
                            activePage !== undefined
                              ? 'text-brand'
                              : shouldBeDark
                              ? 'text-gray-900'
                              : 'text-white'
                          }`}
                        >
                          {item.name}
                        </a>
                      )}
                    </div>
                  ))}
                </div>
                <div>
                  <div className="relative z-20 inline-flex items-center lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">{t('open-menu')}</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                    <SignInButton />
                  </div>
                  <div className="hidden sm:block">
                    <SignUpButton darkText={shouldBeDark} />
                    <SignInButton />
                  </div>
                </div>
              </div>
            </nav>
            <Disclosure.Panel
              as="nav"
              className={`${
                sticky && 'mt-20'
              } fixed right-7 z-30 w-64 bg-white shadow lg:hidden`}
              aria-label={t('global') ?? ''}
            >
              <div className="gap-y-1 px-2 pb-3 pt-2">
                {navigation?.map((item, idx) => (
                  <div key={`${item.name}-${idx}`}>
                    <>
                      {item.dropdown ? (
                        <Disclosure.Panel
                          as="div"
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : ' cursor-pointer text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                            'block rounded-md px-3 py-2 text-base font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <a href="#" onClick={handleDropdownClick}>
                            {item.name}
                            {isDropdownOpen ? (
                              <ChevronUpIcon className="-mt-1 ml-1 inline-block h-4 w-4 text-gray-900" />
                            ) : (
                              <ChevronDownIcon className="-mt-1 ml-1 inline-block h-4 w-4 text-gray-900" />
                            )}
                          </a>
                          <div
                            ref={secondDropdownRef}
                            className={`mt-2 w-48 rounded-md bg-white ${
                              isDropdownOpen ? 'block' : 'hidden'
                            }`}
                          >
                            {item.dropdown?.map((option, odx) => (
                              <a
                                key={`${option.name}-${odx}`}
                                href={option.href}
                                className={`hover:text-brand z-40 flex items-center rounded-md px-4 py-2 text-sm ${
                                  dropdownActivePage === option.href
                                    ? 'text-brand'
                                    : 'text-gray-700'
                                }`}
                              >
                                <div className="relative mr-2 h-5 w-5">
                                  <Image
                                    src={
                                      images[
                                        option.iconUrl as keyof typeof images
                                      ]
                                    }
                                    alt={option.name}
                                    className="text-gray-900"
                                    fill
                                  />
                                </div>
                                {option.name}
                              </a>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      ) : (
                        <Disclosure.Button
                          as="a"
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                            'block rounded-md px-3 py-2 text-base font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      )}
                    </>
                  </div>
                ))}
              </div>
            </Disclosure.Panel>
          </div>
        </>
      )}
    </Disclosure>
  )
}
