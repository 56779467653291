import React from 'react'

import Link from 'next/link'
import { useTranslation } from 'next-i18next'

export const SignInButton = () => {
  const { t } = useTranslation('common')
  return (
    <Link
      href="/sign_in"
      className="bg-brand focus-visible:outline-brand rounded-md px-5 py-2 text-base leading-7 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
    >
      {t('sign-in')}
    </Link>
  )
}
